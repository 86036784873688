<script setup>
import { onMounted, onBeforeMount, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import BinForm from '@/components/bins/BinForm.vue';
import useBins from '@/hooks/useBins';
import pageTitle, { setPageTitle } from '@/utils/pageTitle';
import { useToast } from "vue-toastification";

const currentBinId = ref(null)
const route = useRouter()
const toast = useToast()
const binData = reactive({
    warehouse_id: null,
    name: '',
    staging_area: null,
    not_inventory: null,
    holds_processable_damage: null,
    holds_destructable_damage: null,
    barcode: '',
    notes: ''
})

const { bin, fetchBin, updateBin } = useBins()

onBeforeMount(() => {
    currentBinId.value = route.currentRoute.value.params.id
    fetchCurrentBinData(currentBinId.value)
})

onMounted(() => {
    setPageTitle("Update Bin/Location")
})

const fetchCurrentBinData = async (id) => {
    await fetchBin(id)

    if(bin) {
        binData.warehouse_id = bin.value.warehouse_id
        binData.name = bin.value.name
        binData.staging_area = bin.value.staging_area
        binData.not_inventory = bin.value.not_inventory
        binData.holds_processable_damage = bin.value.holds_processable_damage
        binData.holds_destructable_damage = bin.value.holds_destructable_damage
        binData.barcode = bin.value.barcode ? bin.value.barcode : ''
        binData.notes = bin.value.notes ? bin.value.notes : ''
    }
}

const update = async (updatedBinData) => {
    updateBin(currentBinId.value, updatedBinData);
    toast.success("Bin Updated Successfully.");
    route.push('/bins');
}

</script>

<template>
    <v-card-title>{{pageTitle}}</v-card-title>
    <BinForm btnTitle="Update" :editBinData="binData" @binActionHandler="update"/>
</template>